.lia-kudos-count {
  display: flex;
  align-items: center;
  user-select: none;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--lia-bs-white);
  }
}
